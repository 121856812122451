.uploaderWrapper {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

.uploadSelectorContainer {
  margin-top: 50px;
}

.optionWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.uploadOption {
  width: 45%;
  height: 300px;
  border: 2px solid #808080;
  border-radius: 2em;
  padding: 20px;
  text-align: center;
  transition: background-color 1s ease-out 50ms;
  position: relative;
}

.uploadOption:hover {
  background-color: #808080;
  border-color: white;
}

.optionsIcon {
  width: 60% !important;
  height: 60% !important;
  margin-top: 25px;
  transition: transform 1s ease-out 50ms;
}

.uploadOption:hover .optionsIcon {
  transform: scale(1.2);
}

@media only screen and (max-width: 600px) {
  h2 {
    margin-left: 0;
  }

  .uploaderWrapper {
    width: 95%;
    position: relative;
  }

  .uploadOption {
    margin: 10px auto;
    width: 90%;
    height: 200px;
  }

  .optionsIcon {
    margin-top: 5px;
  }
}
