.AdminContainer {
  padding: 10px;
  margin: auto;
}

.headingContainer {
  width: 80%;
  text-align: center;
}

.contentContainer {
  width: 80%;
  margin: auto;
}

.contentContainer table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.searchWrapper {
  margin-top: 30px;
  text-align: center;
}

.pastSearchesContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.searchWrapper {
  margin-top: 30px;
  text-align: center;
}

.imageCard {
  width: 33%;
}

.searchesErrorContainer {
  margin: auto;
}

.searchesErrorContainer p {
  margin-bottom: 0;
  text-align: center;
}

.buttonContainer {
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}

.transactions table {
  width: 100%;
}

.addUserToFreePurchaseBlock {
  width: 50%;
  margin: auto;
}

.freePurchaseList {
  margin: auto;
  text-align: center;
}

.removableDropdown {
  display: flex;
  justify-content: space-between;
}

.removableDropdown svg {
  margin-top: 27px;
}

.addSchoolButton {
  font-size: 13px;
  padding-top: 10px;
  text-align: center;
}

.userExistsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.checkbox {
  width: 25px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.checkbox[data-checked="unchecked"]:hover {
  background-color: grey;
}

.checkbox[data-checked="checked"] {
  background-color: #484848;
}

.checkbox svg {
  color: white;
}

.checkboxText {
  max-width: calc(100% - 40px);
  padding-left: 15px;
  display: inline;
  margin-bottom: 0;
}

.transactionsHeaderBlock {
  width: 80%;
  margin: auto;
  text-align: center;
}

.transactionsHeaderRow {
  display: flex;
  justify-content: center;
}

.transactionsHeaderRow div {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .headingContainer {
    width: 95%;
    text-align: left;
  }

  .contentContainer {
    width: 100%;
    margin-top: 30px;
    display: block;
  }
  .pastSearchesContainer {
    margin-top: 15px;
    width: 100%;
    display: block;
  }

  .searchesErrorContainer {
    margin-top: 100px;
  }

  .imageCard {
    width: 100%;
    margin-bottom: 20px;
  }

  .addUserToFreePurchaseBlock {
    width: 100%;
    margin: auto;
  }
}
