.collectionContainer {
  width: 80%;
  margin: auto;
  padding: 10px;
}

h2 {
  width: 80%;
  margin: auto;
  text-align: center;
}

.buttonContainer {
  width: 300px;
  align-self: center;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collectionContent {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.collectionContent p {
  margin-bottom: 0;
}

.yearContainer {
  padding: 20px 5%;
}

.imageGridContainer {
  display: flex;
  flex-wrap: wrap;
}

.image {
  width: 33%;
  padding-bottom: 20px;
}

.cartBar {
  position: fixed;
  right: 0;
  width: 10em;
  height: 100%;
  overflow: auto;
  background-color: #333;
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
  border-color: #333;
  padding-bottom: 1em;
  padding-top: 1em;
}

.addAllImagesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorIconContainer svg {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.numberImages {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .collectionContainer {
    width: 95%;
  }

  .collectionContent {
    padding-top: 0;
    height: 80vh;
  }

  h2 {
    width: 95%;
    text-align: left;
    margin-left: 0;
  }

  .yearContainer {
    padding: 20px 0;
  }

  .buttonContainer {
    width: 100%;
    align-self: unset;
  }
  .image {
    width: 49%;
  }

  .numberImages {
    width: 100%;
    text-align: left;
  }
}
