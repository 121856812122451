.uploaderWrapper {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

@media only screen and (max-width: 600px) {
  .uploaderWrapper {
    width: 95%;
  }
}
