.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(93, 93, 93, 0.723);
}

.modalCard {
  max-width: 80%;
  max-height: 80%;
  position: relative;
}

.modalCard img {
  object-fit: contain;
}

.removeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .modalCard {
    transform: scale(1.5);
  }
}
