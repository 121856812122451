.modalWrapper {
  background-color: rgba(128, 128, 128, 0.582);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalCard {
  z-index: 2;
  background-color: white;
  width: 60%;
  max-width: 400px;
  position: relative;
  padding: 40px 40px;
  text-align: center;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
}

.headingFont {
  color: black;
  font-size: 33px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
  text-align: center;
}

.headerContainer {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .modalCard {
    width: 90%;
    text-align: center;
  }
}
