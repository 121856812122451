.fileContainer .uploadPictureContainer {
  box-shadow: unset !important;
  border: none !important;
  background: unset !important;
  padding: 0 !important;
  margin: 0 !important;
  width: unset !important;
  max-width: 400px !important;
}

.chooseFileButton {
  margin-bottom: 100px !important;
  background: #343a40 !important;
  width: 200px;
  border: 1px solid #343a40 !important;

  font-weight: 400 !important;
  vertical-align: middle !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 40px !important;
}

.fileContainer .deleteImage {
  background: #ff0000 !important;
  top: -15px !important;
  right: -15px !important;
}

.fileContainer {
  background: transparent !important;
  padding-top: 0 !important;
}

.uploadIcon {
  width: 170px !important;
  height: 170px !important;
}

@media only screen and (max-width: 600px) {
  .uploadIcon {
    width: 120px !important;
    height: 120px !important;
  }
}
