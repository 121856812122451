@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap");
.ximg {
  width: 25px;
  height: 25px;
}

.headingFont {
  color: black;
  font-size: 33px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
}

.headerContainer {
  width: 100%;
  text-align: center;
}

.buttonContainer {
  margin-top: 24px;
}

.loaderContainer {
  text-align: center;
  margin-top: 24px;
}

.loginErrorMessage {
  margin-top: 12px;
}

.removableDropdown {
  display: flex;
  justify-content: space-between;
}

.removableDropdown svg {
  margin-top: 27px;
}

.addSchoolButton {
  font-size: 13px;
  padding-top: 10px;
}

.loader {
  width: 24px;
}

.verifyStudenText {
  padding-top: 32px;
}

.loggingYouIn {
  margin-top: 20px;
}

.spamFolderMessage {
  margin-top: 20px;
}

.validationErrorMessage {
  margin-top: 12px;
  text-align: left;
}

.validationErrorMessageCenter {
  margin-top: 12px;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .loginContainer {
    margin: auto;
    z-index: 999;
    padding: 40px 32px;
    width: 370px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .removableDropdown div {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .headingFont {
    text-align: left;
    margin-left: 0;
  }
  .loginWrapper {
    display: inline-block;
  }
  .loginContainer {
    padding: 12px;
    text-align: center;
  }
  .removableDropdown div {
    width: 93%;
  }

  .logginInContainer {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
