.not-found-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.not-found-icon {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 40px;
}

.not-found-icon svg {
  width: 100px;
  height: 100px;
}
