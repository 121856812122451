.navContainer {
  z-index: 100;
}

.screenContainer {
  font-family: "Montserrat", sans-serif;
  background-color: white;
  background-size: cover;
  position: center;
}

@media only screen and (min-width: 600px) {
  .screenContainer {
    padding-top: 20px;
  }
}
