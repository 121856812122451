.activatedLink {
  background-color: #808080;
}

@media only screen and (min-width: 600px) {
  .navBarContainer {
    margin-bottom: 0;
  }

  .optionsIcon {
    display: none;
  }

  .font {
    color: white;
    font-size: 20px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
  }
  .mobileLogo {
    display: none;
  }

  .navBarIcon {
    margin-top: -3px;
    margin-right: 2px;
  }

  .navBarContainer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #484848;
    position: relative;
    width: 100%;
  }

  .navBarContainer li {
    float: left;
    text-decoration: none;

    font-size: 1.5em;
    margin-bottom: 0;
  }

  .navBarContainer li a {
    display: block;
    padding: 8px 1.5em;
    color: white;
    text-decoration: none;
    margin-bottom: 0;
  }

  .navBarContainer li a:hover {
    background-color: #a9a9a9;
    color: aliceblue;
    text-decoration: none;
  }

  .emptyContainer {
    display: none;
  }

  .emptyContainerVisible {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .navBarContainer {
    position: fixed;
    display: inline-block;
    height: 100vh;
    width: 0;
    margin-bottom: 0;
    background-color: rgb(223, 223, 223);
    padding-top: 12px;
    z-index: 2;
    transition: 0.5s;
  }
  .optionsIcon {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 18px;
    z-index: 10;
  }
  .font {
    color: white;
    font-size: 16px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    color: black;
  }
  .mobileLogo {
    overflow: hidden;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 0 5%;
  }
  .linkContainer {
    width: 100%;
  }
  .navBarIcon {
    margin-top: -4px;
    margin-right: 2px;
  }
  .navBarContainer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .navBarContainer li {
    text-decoration: none;
    padding: 0 0.5em;
    margin-bottom: 0;
    padding-bottom: 8px;
    overflow: hidden;
  }
  .navBarContainer > li a {
    color: white;
    text-decoration: none;
    margin-bottom: 0;
  }
  .emptyContainer {
    position: fixed;
    right: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: height 0ms 500ms, opacity 500ms 0ms;
  }
  .emptyContainerVisible {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 100%;
    display: inline-block;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: height 0ms 0ms, opacity 500ms 0ms;
  }
}
