.hide {
  display: none;
}
.modalBackground {
  background-color: rgba(128, 128, 128, 0.582);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  z-index: 2;
  background-color: white;
  width: 60%;
  max-width: 400px;
  position: relative;
  padding: 40px 40px;
}

.loaderContainer {
  text-align: center;
  margin-top: 30px;
}

.loader {
  width: 24px;
}

.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.errorMessage {
  padding-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 90%;
  }
}
