.inputFieldContainer {
  padding: 2px 0;
  display: block;
  position: relative;
}
.labelContainer {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
}

.labelFont {
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.helpBlock {
  display: inline-block;
  height: 13px;
  color: red;
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-top: 2px;
  text-align: left;
  width: 100%;
}
.showHideButton {
  position: absolute;
  right: 10px;
  top: 30px;
}
