.bottomDetailsContainer {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #484848;
  z-index: 100;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  padding: 20px 20px;
  justify-content: space-around;
  width: 300px;
  margin-left: 100px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row p {
  margin-bottom: 0;
}

.clearCartContainer {
  position: fixed;
  bottom: 5px;
  right: 200px;
  color: white;
  display: flex;
  flex-direction: column;
}

.openCartContainer {
  position: fixed;
  bottom: 5px;
  right: 100px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cartIcon {
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .bottomDetailsContainer {
    border-radius: 8px 8px 0 0;
  }
  .detailsContainer {
    width: calc(80% - 50px);
    margin-left: 0;
  }

  .clearCartContainer {
    display: none;
  }

  .openCartContainer {
    right: 10px;
  }
}
