.aboutUsContainer {
  width: 100%;
  padding-bottom: 30px;
}

.textContainer {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}

.textContainer h3 {
  display: inline;
}

.textContainer p {
  margin-bottom: 0;
}

.logo {
  width: 50%;
  margin-bottom: 40px;
}

.textContainer ul {
  width: 350px;
  text-align: left;
  margin: 20px auto 0;
}

.inputFieldWrapper {
  margin: auto;
  width: 300px;
  text-align: left;
}

.inputFieldContainer {
  display: flex;
  align-items: center;
}

.inputFieldContainer h5 {
  margin-bottom: -20px;
  width: 150px;
}

.pricingDetails {
  display: flex;
  margin: auto;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
}

@media only screen and (max-width: 600px) {
  .aboutUsContainer {
    padding: 10px;
    width: 95%;
    margin: auto;
  }

  .textContainer {
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }

  .logo {
    width: 80%;
  }
}
