.searchWrapper {
  text-align: center;
}

.pastSearchesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.imageCard {
  width: 33%;
  margin-bottom: 5px;
}

.searchesErrorContainer {
  margin: 30px auto;
}

.searchesErrorContainer p {
  margin-bottom: 0;
  text-align: center;
}

.errorIconContainer svg {
  width: 100px;
  height: 100px;
}

.goBackButtonContainer {
  margin: auto;
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .pastSearchesContainer {
    margin-top: 15px;
    width: 100%;
    display: block;
  }

  .searchesErrorContainer {
    margin-top: 50px;
  }

  .imageCard {
    width: 100%;
    margin-bottom: 20px;
  }
}
