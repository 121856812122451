.checkoutContainer {
  width: 80%;
  margin: auto;
  padding: 10px;
}

.collectionContainer {
  width: 100%;
  height: 100%;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
}

.orderSummary button {
  margin-top: 16px;
}

.orderContents {
  display: flex;
  flex-wrap: wrap;
}

.buttonContainer {
  margin: auto;
  width: 220px;
  align-self: center;
}

.emptyIcon {
  font-size: 150px !important;
}

@media only screen and (min-width: 600px) {
  h2 {
    text-align: center;
  }
  .noImagesContainer {
    width: 70%;
    margin-left: 15%;
    padding-top: 15%;
    text-align: center;
  }

  .collectionContent {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .orderSummary {
    width: 30%;
    padding-right: 30px;
  }

  .orderSummary h5 {
    margin-bottom: 40px;
  }

  .orderSummary .summaryValue {
    text-align: right;
  }

  .orderContents {
    width: 70%;
  }

  .imageCard {
    width: calc(33% - 5px);
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .checkoutContainer {
    width: 95%;
  }

  h2 {
    margin-left: 0;
  }

  .orderSummary {
    margin: 30px 0 30px;
  }

  .orderSummary h5 {
    margin-bottom: 13px;
  }

  .summaryRow p {
    margin-bottom: 5px;
  }

  .imageCard {
    width: calc(50% - 5px);
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .noImagesContainer {
    height: calc(100vh - 70px);
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .noImagesContentWrapper {
    margin-top: -2em;
  }

  .emptyIcon {
    font-size: 100px !important;
    align-self: center;
  }
}
