.buttonContainer {
  width: 100%;
  margin-top: 15px;
}
.button {
  color: #343a40;
  background-color: transparent;
  border: 1px solid #343a40;
  transition: 0.3s;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;

  border-radius: 40px;
}

.button {
  background-color: rgb(255, 255, 255);
}

.button.primaryButton {
  color: white;
  background-color: #343a40;
}

.button.primaryButton:disabled {
  color: rgb(192, 192, 192);
  background-color: #555555;
}

.button.disabled {
  color: #343a40;
  background-color: transparent;
  pointer-events: none;
}
.button.disabled:hover {
  color: #343a40;
  background-color: transparent;
  pointer-events: none;
}

.button:hover:not(:disabled) {
  color: white;
  background-color: #343a40;
  text-decoration: none;
}

.button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.loader {
  height: 20px;
  width: 20px;
}
