.collectionContainer {
  padding: 10px;
}

.headingContainer {
  width: 80%;
  margin: auto;
  text-align: center;
}

.errorContainer {
  width: 80%;
  margin: auto;
  margin-top: 70px;
  text-align: center;
}

.retryIcon svg {
  width: 100px;
  height: 100px;
}

.imagesContainer {
  width: 100%;
}

.transactionBlock {
  padding: 20px;
  box-shadow: 1px 1px 1px 1px #888888;
  width: 80%;
  border-radius: 10px;
  margin: 20px auto;
}

details > summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.rightColumn {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95px;
}

details[open] summary {
  padding-bottom: 20px;
}

.progressCircle {
  /* width: 50px; */
  margin: auto;
  text-align: center;
}

.arrowIcon {
  transform: rotate(0deg);
}

details[open] summary .arrowIcon {
  transform: rotate(-90deg);
}

.orderBlock {
  padding: 20px 0 10px;
  border-top: 1.5px solid rgba(72, 72, 72, 0.3);
  display: flex;
  flex-wrap: wrap;
}

.clickToPreviewText {
  opacity: 0.6;
  font-size: 14px;
}

.imageCard {
  width: 33%;
  padding-bottom: 20px;
}

.downloadAllContainer {
  text-align: center;
}

.continueOrderBlock {
  display: flex;
  justify-content: center;
}
.continueOrderContainer {
  max-width: 350px;
  text-align: center;
}

.hintText {
  text-align: center;
  margin-top: 30px;
}

.emptyIcon {
  font-size: 150px !important;
}

.buttonContainer {
  width: 200px;
  margin: 0 auto;
}

.downloadProgressButtonContainer {
  max-width: 250px;
  margin: 25px auto;
}

@media only screen and (min-width: 600px) {
  .rightColumn svg {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .headingContainer {
    width: 95%;
    text-align: left;
  }

  h2 {
    margin-left: 0;
  }

  .errorContainer {
    height: 65vh;
    text-align: center;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .emptyIcon {
    font-size: 100px !important;
    align-self: center;
  }

  .transactionBlock {
    width: 95%;
  }

  .imageCard {
    width: 49%;
  }

  .progressCircle {
    width: 24px;
  }
}
