.dropdownContainer {
  width: 100%;
  display: inline-block;
}

.labelContainer {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
}

.labelFont {
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.hintTextContainer {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
  display: block;
}

.hintTextFont {
  color: black;
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.dropdown {
  color: #343a40;
  background-color: transparent;
  border: 1px solid #343a40;
  transition: 0.3s;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-radius: 40px;
}
