.sideBarContainer {
  position: fixed;
  display: inline-block;
  height: 100vh;
  width: 0;
  right: 0;
  top: 0;
  margin-bottom: 0;
  background-color: rgb(223, 223, 223);
  transition: 0.5s;
  z-index: 10000;
  overflow: auto;
}

.emptyContainer {
  position: fixed;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: height 0ms 500ms, opacity 500ms 0ms;
}
.emptyContainerVisible {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: inline-block;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: height 0ms 0ms, opacity 500ms 0ms;
}

.headerContainer {
  padding: 10px 0px;
  width: 100%;
  position: relative;
}

.closeCart {
  position: absolute;
  right: 10px;
  top: 10px;
}

.header {
  width: 80%;
  margin-left: 10%;
  font-size: 25px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.subHeading {
  width: 80%;
  margin-left: 10%;
}

.summaryContainer {
  width: 80%;
  margin-left: 10%;
}

.summaryColumn {
  width: 50%;
  display: inline-block;
}

.summaryColumn:last-child {
  text-align: right;
}

.totalText {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.itemsText {
  font-size: 15px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.priceText {
  font-size: 25px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.checkoutButton {
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.imagesContainer {
  width: 100%;
  padding-bottom: 20px;
}

.imageContainer {
  position: relative;
  width: 80%;
  margin-top: 10px;
  margin-left: 10%;
}

img {
  width: 100%;
  border-radius: 7px;
}

.removeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideImageLoader {
  height: 200px;
  display: flex;
  align-items: center;
}
