.uploaderWrapper {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

.heading {
  text-align: center;
}

.topMargin {
  margin-top: 30px;
  margin-bottom: 20px;
}

.contactUsButton {
  width: 200px;
  display: block;
  margin: auto;
}

.noSearchesLeft {
  margin-top: 30px;
}

.uploaderContainerUploaded img:first-child,
.uploaderContainerUploaded p,
.uploaderContainerUploaded button {
  display: none;
}

.uploaderContainerNotUploaded {
  position: relative;
}

.imageCaption {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 10px auto;
}

.checkbox {
  width: 25px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.checkbox[data-checked="unchecked"]:hover {
  background-color: grey;
}

.checkbox[data-checked="checked"] {
  background-color: #484848;
}

.checkbox svg {
  color: white;
}

.checkboxText {
  max-width: calc(100% - 40px);
  padding-left: 15px;
  display: inline;
  margin-bottom: 0;
}

.goButtonContainer {
  width: 200px;
  margin: 20px auto 0;
}

.goButtonContainer button {
  display: block !important;
}

.goBackButtonContainer {
  margin: -115px auto 0;
  width: 200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 600px) {
  .uploaderContainerNotUploaded {
    margin-top: 50px;
  }

  .topMargin {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    margin-left: 0;
  }

  .uploaderWrapper {
    width: 95%;
    position: relative;
  }

  .noSearchesLeft {
    margin-top: 100px;
  }

  .fullHeight {
    height: 100vh;
  }

  .uploaderContainer {
    width: 80%;
    margin: auto;
  }

  .uploaderContainerNotUploaded {
    padding-top: 40px;
  }

  .imageCaption {
    width: 100%;
  }

  .goButtonContainer {
    width: 80%;
  }
}
