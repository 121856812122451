.cardWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.cardContainer {
  width: 100%;
  height: fit-content;
  position: relative;
}

.imageCard {
  width: 98%;
}

.image {
  object-fit: cover;
}

.keyContainer {
  position: absolute;
  bottom: 0;
  width: 98%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.fileName {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 2px 5px;
  background-color: white;
  border-radius: 20px;
  font-size: 13px;
}

.iconWrapper {
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cartButtonWrapper {
  height: 30px;
  background-color: white;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
}

.errorContainer img {
  display: none;
}

.imageTitle {
  text-align: center;
  color: white;
  font-size: 0.9em;
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.errorContainer > svg {
  width: 50px;
  height: 50px;
}

.errorContainer > p {
  opacity: 0.5;
  padding-top: 10px;
}

.downloadError {
  font-size: 12px;
  opacity: 0.6;
  padding: 0 5px;
}
