@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.Home_homeContainer__3tIhR {
  width: 100%;
  height: calc(95vh - 46px);
  padding: 0 30px;
  text-align: center;
}

.Home_textContainer__wnA7G {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}

h1 {
  width: 80%;
  margin: 0 auto 30px !important;
}

h4 {
  margin-bottom: 30px !important;
}

.Home_textContainer__wnA7G h3 {
  display: inline;
}

.Home_textContainer__wnA7G p {
  margin-bottom: 0;
}

.Home_buttonContainer__72cC6 {
  width: 300px;
  margin: 0 auto 10px;
}

.Home_logo__3auvB {
  width: 40%;
  height: auto;
  object-fit: cover;
  margin: auto;
}

.Home_topMargin__3xZsM {
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .Home_homeContainer__3tIhR {
    padding: 10px;
    width: 95%;
    margin: auto;
    height: 95vh;
  }

  .Home_textContainer__wnA7G {
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .Home_logo__3auvB {
    /* width: 80%; */
    display: none;
  }
}

.Button_buttonContainer__LjXQt {
  width: 100%;
  margin-top: 15px;
}
.Button_button__2cZ5P {
  color: #343a40;
  background-color: transparent;
  border: 1px solid #343a40;
  transition: 0.3s;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;

  border-radius: 40px;
}

.Button_button__2cZ5P {
  background-color: rgb(255, 255, 255);
}

.Button_button__2cZ5P.Button_primaryButton__41aq5 {
  color: white;
  background-color: #343a40;
}

.Button_button__2cZ5P.Button_primaryButton__41aq5:disabled {
  color: rgb(192, 192, 192);
  background-color: #555555;
}

.Button_button__2cZ5P.Button_disabled__nbT6h {
  color: #343a40;
  background-color: transparent;
  pointer-events: none;
}
.Button_button__2cZ5P.Button_disabled__nbT6h:hover {
  color: #343a40;
  background-color: transparent;
  pointer-events: none;
}

.Button_button__2cZ5P:hover:not(:disabled) {
  color: white;
  background-color: #343a40;
  text-decoration: none;
}

.Button_button__2cZ5P:not(:disabled):not(.Button_disabled__nbT6h) {
  cursor: pointer;
}

.Button_loader__qPOLI {
  height: 20px;
  width: 20px;
}

.Collection_collectionContainer__3OrxL {
  width: 80%;
  margin: auto;
  padding: 10px;
}

h2 {
  width: 80%;
  margin: auto;
  text-align: center;
}

.Collection_buttonContainer__2XT4l {
  width: 300px;
  align-self: center;
}

.Collection_loading-container__w9c_r {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Collection_collectionContent__jGHmx {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.Collection_collectionContent__jGHmx p {
  margin-bottom: 0;
}

.Collection_yearContainer__2uMvO {
  padding: 20px 5%;
}

.Collection_imageGridContainer__iIw6j {
  display: flex;
  flex-wrap: wrap;
}

.Collection_image__2Mib2 {
  width: 33%;
  padding-bottom: 20px;
}

.Collection_cartBar__3H4kC {
  position: fixed;
  right: 0;
  width: 10em;
  height: 100%;
  overflow: auto;
  background-color: #333;
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
  border-color: #333;
  padding-bottom: 1em;
  padding-top: 1em;
}

.Collection_addAllImagesContainer__2Y-Oe {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Collection_errorIconContainer__3_HLP svg {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.Collection_numberImages__1c30J {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .Collection_collectionContainer__3OrxL {
    width: 95%;
  }

  .Collection_collectionContent__jGHmx {
    padding-top: 0;
    height: 80vh;
  }

  h2 {
    width: 95%;
    text-align: left;
    margin-left: 0;
  }

  .Collection_yearContainer__2uMvO {
    padding: 20px 0;
  }

  .Collection_buttonContainer__2XT4l {
    width: 100%;
    align-self: unset;
  }
  .Collection_image__2Mib2 {
    width: 49%;
  }

  .Collection_numberImages__1c30J {
    width: 100%;
    text-align: left;
  }
}

.ImageCard_cardWrapper__3Wcef {
  display: flex;
  align-items: center;
  height: 100%;
}

.ImageCard_cardContainer__21CyB {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.ImageCard_imageCard__3Of5x {
  width: 98%;
}

.ImageCard_image__1erlc {
  object-fit: cover;
}

.ImageCard_keyContainer__W_cuB {
  position: absolute;
  bottom: 0;
  width: 98%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.ImageCard_fileName__2LReH {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 2px 5px;
  background-color: white;
  border-radius: 20px;
  font-size: 13px;
}

.ImageCard_iconWrapper__1MGKu {
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ImageCard_cartButtonWrapper__1lsOD {
  height: 30px;
  background-color: white;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
}

.ImageCard_errorContainer__24Mua img {
  display: none;
}

.ImageCard_imageTitle__2dagC {
  text-align: center;
  color: white;
  font-size: 0.9em;
}

.ImageCard_errorContainer__24Mua {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.ImageCard_errorContainer__24Mua > svg {
  width: 50px;
  height: 50px;
}

.ImageCard_errorContainer__24Mua > p {
  opacity: 0.5;
  padding-top: 10px;
}

.ImageCard_downloadError__3h1e8 {
  font-size: 12px;
  opacity: 0.6;
  padding: 0 5px;
}

.ImageCardModal_modalWrapper__2Kdau {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(93, 93, 93, 0.723);
}

.ImageCardModal_modalCard__28Z2f {
  max-width: 80%;
  max-height: 80%;
  position: relative;
}

.ImageCardModal_modalCard__28Z2f img {
  object-fit: contain;
}

.ImageCardModal_removeIcon__JjMVP {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .ImageCardModal_modalCard__28Z2f {
    transform: scale(1.5);
  }
}

.Login_ximg__2Zcus {
  width: 25px;
  height: 25px;
}

.Login_headingFont__3ycMl {
  color: black;
  font-size: 33px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
}

.Login_headerContainer__1QfHa {
  width: 100%;
  text-align: center;
}

.Login_buttonContainer__2fM5B {
  margin-top: 24px;
}

.Login_loaderContainer__VUKQ- {
  text-align: center;
  margin-top: 24px;
}

.Login_loginErrorMessage__p3xZ_ {
  margin-top: 12px;
}

.Login_removableDropdown__1qycD {
  display: flex;
  justify-content: space-between;
}

.Login_removableDropdown__1qycD svg {
  margin-top: 27px;
}

.Login_addSchoolButton__2lCFu {
  font-size: 13px;
  padding-top: 10px;
}

.Login_loader__2VnmI {
  width: 24px;
}

.Login_verifyStudenText__1PHBq {
  padding-top: 32px;
}

.Login_loggingYouIn__34KZP {
  margin-top: 20px;
}

.Login_spamFolderMessage__1A3cu {
  margin-top: 20px;
}

.Login_validationErrorMessage__ogI8D {
  margin-top: 12px;
  text-align: left;
}

.Login_validationErrorMessageCenter__47nxP {
  margin-top: 12px;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .Login_loginContainer__1QKC- {
    margin: auto;
    z-index: 999;
    padding: 40px 32px;
    width: 370px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .Login_removableDropdown__1qycD div {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .Login_headingFont__3ycMl {
    text-align: left;
    margin-left: 0;
  }
  .Login_loginWrapper__3VnDR {
    display: inline-block;
  }
  .Login_loginContainer__1QKC- {
    padding: 12px;
    text-align: center;
  }
  .Login_removableDropdown__1qycD div {
    width: 93%;
  }

  .Login_logginInContainer__cYnQK {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.InputField_inputFieldContainer__1A8Ie {
  padding: 2px 0;
  display: block;
  position: relative;
}
.InputField_labelContainer__14Tct {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
}

.InputField_labelFont__UMoqr {
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.InputField_helpBlock__2Y1C4 {
  display: inline-block;
  height: 13px;
  color: red;
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-top: 2px;
  text-align: left;
  width: 100%;
}
.InputField_showHideButton__2fsT4 {
  position: absolute;
  right: 10px;
  top: 30px;
}

.Upload_uploaderWrapper__37DEX {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

@media only screen and (max-width: 600px) {
  .Upload_uploaderWrapper__37DEX {
    width: 95%;
  }
}

.PastSearchImageCard_cardContainer__g_6qY {
  width: 100%;
  position: relative;
}

.PastSearchImageCard_imageCard__3zwc5 {
  width: 98%;
}

.PastSearchImageCard_image__3ECfW {
  width: 100%;
  object-fit: cover;
}

.PastSearchImageCard_searchAgain__3tAjU {
  position: absolute;
  bottom: 5px;
  width: 98%;
}
.PastSearchImageCard_searchAgain__3tAjU > div {
  width: 150px;
  margin: auto;
}

.PastSearchImageCard_errorContainer___qxtZ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.PastSearchImageCard_errorContainer___qxtZ > svg {
  width: 50px;
  height: 50px;
}

.PastSearchImageCard_errorContainer___qxtZ > p {
  opacity: 0.5;
  padding-top: 10px;
}

.PastSearchImages_searchWrapper__LAWod {
  text-align: center;
}

.PastSearchImages_pastSearchesContainer__2iwYb {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.PastSearchImages_imagesContainer__GdzWh {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.PastSearchImages_imageCard__VY3ms {
  width: 33%;
  margin-bottom: 5px;
}

.PastSearchImages_searchesErrorContainer__3g1N4 {
  margin: 30px auto;
}

.PastSearchImages_searchesErrorContainer__3g1N4 p {
  margin-bottom: 0;
  text-align: center;
}

.PastSearchImages_errorIconContainer__358lt svg {
  width: 100px;
  height: 100px;
}

.PastSearchImages_goBackButtonContainer__1vFCV {
  margin: auto;
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .PastSearchImages_pastSearchesContainer__2iwYb {
    margin-top: 15px;
    width: 100%;
    display: block;
  }

  .PastSearchImages_searchesErrorContainer__3g1N4 {
    margin-top: 50px;
  }

  .PastSearchImages_imageCard__VY3ms {
    width: 100%;
    margin-bottom: 20px;
  }
}

.UploadSelector_uploaderWrapper__2Ur1n {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

.UploadSelector_uploadSelectorContainer__1gQ2C {
  margin-top: 50px;
}

.UploadSelector_optionWrapper__2KiJI {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.UploadSelector_uploadOption__2CPT4 {
  width: 45%;
  height: 300px;
  border: 2px solid #808080;
  border-radius: 2em;
  padding: 20px;
  text-align: center;
  transition: background-color 1s ease-out 50ms;
  position: relative;
}

.UploadSelector_uploadOption__2CPT4:hover {
  background-color: #808080;
  border-color: white;
}

.UploadSelector_optionsIcon__-zOG7 {
  width: 60% !important;
  height: 60% !important;
  margin-top: 25px;
  transition: transform 1s ease-out 50ms;
}

.UploadSelector_uploadOption__2CPT4:hover .UploadSelector_optionsIcon__-zOG7 {
  transform: scale(1.2);
}

@media only screen and (max-width: 600px) {
  h2 {
    margin-left: 0;
  }

  .UploadSelector_uploaderWrapper__2Ur1n {
    width: 95%;
    position: relative;
  }

  .UploadSelector_uploadOption__2CPT4 {
    margin: 10px auto;
    width: 90%;
    height: 200px;
  }

  .UploadSelector_optionsIcon__-zOG7 {
    margin-top: 5px;
  }
}

.fileContainer .uploadPictureContainer {
  box-shadow: unset !important;
  border: none !important;
  background: unset !important;
  padding: 0 !important;
  margin: 0 !important;
  width: unset !important;
  max-width: 400px !important;
}

.chooseFileButton {
  margin-bottom: 100px !important;
  background: #343a40 !important;
  width: 200px;
  border: 1px solid #343a40 !important;

  font-weight: 400 !important;
  vertical-align: middle !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 40px !important;
}

.fileContainer .deleteImage {
  background: #ff0000 !important;
  top: -15px !important;
  right: -15px !important;
}

.fileContainer {
  background: transparent !important;
  padding-top: 0 !important;
}

.uploadIcon {
  width: 170px !important;
  height: 170px !important;
}

@media only screen and (max-width: 600px) {
  .uploadIcon {
    width: 120px !important;
    height: 120px !important;
  }
}

.Uploader_uploaderWrapper__2Imzd {
  width: 80%;
  margin: auto;
  padding: 10px 10px 20px;
}

.Uploader_heading__Z5n4F {
  text-align: center;
}

.Uploader_topMargin__OICxH {
  margin-top: 30px;
  margin-bottom: 20px;
}

.Uploader_contactUsButton__1Sq97 {
  width: 200px;
  display: block;
  margin: auto;
}

.Uploader_noSearchesLeft__3oIRT {
  margin-top: 30px;
}

.Uploader_uploaderContainerUploaded__2pQmf img:first-child,
.Uploader_uploaderContainerUploaded__2pQmf p,
.Uploader_uploaderContainerUploaded__2pQmf button {
  display: none;
}

.Uploader_uploaderContainerNotUploaded__OzVKg {
  position: relative;
}

.Uploader_imageCaption__2QLQs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 10px auto;
}

.Uploader_checkbox__1wD3d {
  width: 25px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.Uploader_checkbox__1wD3d[data-checked="unchecked"]:hover {
  background-color: grey;
}

.Uploader_checkbox__1wD3d[data-checked="checked"] {
  background-color: #484848;
}

.Uploader_checkbox__1wD3d svg {
  color: white;
}

.Uploader_checkboxText__3xwYo {
  max-width: calc(100% - 40px);
  padding-left: 15px;
  display: inline;
  margin-bottom: 0;
}

.Uploader_goButtonContainer__2mPOq {
  width: 200px;
  margin: 20px auto 0;
}

.Uploader_goButtonContainer__2mPOq button {
  display: block !important;
}

.Uploader_goBackButtonContainer__L6TKP {
  margin: -115px auto 0;
  width: 200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 600px) {
  .Uploader_uploaderContainerNotUploaded__OzVKg {
    margin-top: 50px;
  }

  .Uploader_topMargin__OICxH {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    margin-left: 0;
  }

  .Uploader_uploaderWrapper__2Imzd {
    width: 95%;
    position: relative;
  }

  .Uploader_noSearchesLeft__3oIRT {
    margin-top: 100px;
  }

  .Uploader_fullHeight__3puJ4 {
    height: 100vh;
  }

  .Uploader_uploaderContainer__3UyVP {
    width: 80%;
    margin: auto;
  }

  .Uploader_uploaderContainerNotUploaded__OzVKg {
    padding-top: 40px;
  }

  .Uploader_imageCaption__2QLQs {
    width: 100%;
  }

  .Uploader_goButtonContainer__2mPOq {
    width: 80%;
  }
}

.NavBar_activatedLink__2F4_r {
  background-color: #808080;
}

@media only screen and (min-width: 600px) {
  .NavBar_navBarContainer__1Fp0x {
    margin-bottom: 0;
  }

  .NavBar_optionsIcon__VKTGj {
    display: none;
  }

  .NavBar_font__2iZ3u {
    color: white;
    font-size: 20px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
  }
  .NavBar_mobileLogo__3G1eh {
    display: none;
  }

  .NavBar_navBarIcon__2Rkfs {
    margin-top: -3px;
    margin-right: 2px;
  }

  .NavBar_navBarContainer__1Fp0x ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #484848;
    position: relative;
    width: 100%;
  }

  .NavBar_navBarContainer__1Fp0x li {
    float: left;
    text-decoration: none;

    font-size: 1.5em;
    margin-bottom: 0;
  }

  .NavBar_navBarContainer__1Fp0x li a {
    display: block;
    padding: 8px 1.5em;
    color: white;
    text-decoration: none;
    margin-bottom: 0;
  }

  .NavBar_navBarContainer__1Fp0x li a:hover {
    background-color: #a9a9a9;
    color: aliceblue;
    text-decoration: none;
  }

  .NavBar_emptyContainer__1S3V7 {
    display: none;
  }

  .NavBar_emptyContainerVisible__2FrRB {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .NavBar_navBarContainer__1Fp0x {
    position: fixed;
    display: inline-block;
    height: 100vh;
    width: 0;
    margin-bottom: 0;
    background-color: rgb(223, 223, 223);
    padding-top: 12px;
    z-index: 2;
    transition: 0.5s;
  }
  .NavBar_optionsIcon__VKTGj {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 18px;
    z-index: 10;
  }
  .NavBar_font__2iZ3u {
    color: white;
    font-size: 16px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    color: black;
  }
  .NavBar_mobileLogo__3G1eh {
    overflow: hidden;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 0 5%;
  }
  .NavBar_linkContainer__1kG-P {
    width: 100%;
  }
  .NavBar_navBarIcon__2Rkfs {
    margin-top: -4px;
    margin-right: 2px;
  }
  .NavBar_navBarContainer__1Fp0x ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .NavBar_navBarContainer__1Fp0x li {
    text-decoration: none;
    padding: 0 0.5em;
    margin-bottom: 0;
    padding-bottom: 8px;
    overflow: hidden;
  }
  .NavBar_navBarContainer__1Fp0x > li a {
    color: white;
    text-decoration: none;
    margin-bottom: 0;
  }
  .NavBar_emptyContainer__1S3V7 {
    position: fixed;
    right: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: height 0ms 500ms, opacity 500ms 0ms;
  }
  .NavBar_emptyContainerVisible__2FrRB {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 100%;
    display: inline-block;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: height 0ms 0ms, opacity 500ms 0ms;
  }
}

.ReferAFriendModal_modalWrapper__2qP94 {
  background-color: rgba(128, 128, 128, 0.582);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.ReferAFriendModal_modalCard__2xbz7 {
  z-index: 2;
  background-color: white;
  width: 60%;
  max-width: 400px;
  position: relative;
  padding: 40px 40px;
  text-align: center;
}

.ReferAFriendModal_closeModal__1aFVh {
  position: absolute;
  top: 5px;
  right: 5px;
}

.ReferAFriendModal_headingFont__32Uct {
  color: black;
  font-size: 33px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
  text-align: center;
}

.ReferAFriendModal_headerContainer__oA9Ug {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .ReferAFriendModal_modalCard__2xbz7 {
    width: 90%;
    text-align: center;
  }
}

.not-found-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.not-found-icon {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 40px;
}

.not-found-icon svg {
  width: 100px;
  height: 100px;
}

.PaymentDetailModal_hide__3KSHf {
  display: none;
}
.PaymentDetailModal_modalBackground__3RImk {
  background-color: rgba(128, 128, 128, 0.582);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.PaymentDetailModal_modal__1QvgC {
  z-index: 2;
  background-color: white;
  width: 60%;
  max-width: 400px;
  position: relative;
  padding: 40px 40px;
}

.PaymentDetailModal_loaderContainer__15H0J {
  text-align: center;
  margin-top: 30px;
}

.PaymentDetailModal_loader__1G2nS {
  width: 24px;
}

.PaymentDetailModal_closeIcon__CvgFb {
  position: absolute;
  right: 10px;
  top: 10px;
}

.PaymentDetailModal_errorMessage__2i3Yi {
  padding-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .PaymentDetailModal_modal__1QvgC {
    width: 90%;
  }
}

.DropdownList_dropdownContainer__SBpF0 {
  width: 100%;
  display: inline-block;
}

.DropdownList_labelContainer__Ov6pf {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
}

.DropdownList_labelFont__30vKw {
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.DropdownList_hintTextContainer___lcT0 {
  height: 20px;
  margin-bottom: 2px;
  text-align: left;
  display: block;
}

.DropdownList_hintTextFont__3BNsR {
  color: black;
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.DropdownList_dropdown__3NM4w {
  color: #343a40;
  background-color: transparent;
  border: 1px solid #343a40;
  transition: 0.3s;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-radius: 40px;
}

.Checkout_checkoutContainer__gF5Sa {
  width: 80%;
  margin: auto;
  padding: 10px;
}

.Checkout_collectionContainer__2y8QO {
  width: 100%;
  height: 100%;
}

.Checkout_summaryRow__1zjMb {
  display: flex;
  justify-content: space-between;
}

.Checkout_orderSummary__161Mx button {
  margin-top: 16px;
}

.Checkout_orderContents__1kFJs {
  display: flex;
  flex-wrap: wrap;
}

.Checkout_buttonContainer__1kwHK {
  margin: auto;
  width: 220px;
  align-self: center;
}

.Checkout_emptyIcon__j2xKU {
  font-size: 150px !important;
}

@media only screen and (min-width: 600px) {
  h2 {
    text-align: center;
  }
  .Checkout_noImagesContainer__1rNpJ {
    width: 70%;
    margin-left: 15%;
    padding-top: 15%;
    text-align: center;
  }

  .Checkout_collectionContent__2uePT {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .Checkout_orderSummary__161Mx {
    width: 30%;
    padding-right: 30px;
  }

  .Checkout_orderSummary__161Mx h5 {
    margin-bottom: 40px;
  }

  .Checkout_orderSummary__161Mx .Checkout_summaryValue__2uaqf {
    text-align: right;
  }

  .Checkout_orderContents__1kFJs {
    width: 70%;
  }

  .Checkout_imageCard__1Wk5r {
    width: calc(33% - 5px);
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .Checkout_checkoutContainer__gF5Sa {
    width: 95%;
  }

  h2 {
    margin-left: 0;
  }

  .Checkout_orderSummary__161Mx {
    margin: 30px 0 30px;
  }

  .Checkout_orderSummary__161Mx h5 {
    margin-bottom: 13px;
  }

  .Checkout_summaryRow__1zjMb p {
    margin-bottom: 5px;
  }

  .Checkout_imageCard__1Wk5r {
    width: calc(50% - 5px);
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .Checkout_noImagesContainer__1rNpJ {
    height: calc(100vh - 70px);
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .Checkout_noImagesContentWrapper__1vsj8 {
    margin-top: -2em;
  }

  .Checkout_emptyIcon__j2xKU {
    font-size: 100px !important;
    align-self: center;
  }
}

.root_navContainer__2hPl0 {
  z-index: 100;
}

.root_screenContainer__3_W5K {
  font-family: "Montserrat", sans-serif;
  background-color: white;
  background-size: cover;
  position: center;
}

@media only screen and (min-width: 600px) {
  .root_screenContainer__3_W5K {
    padding-top: 20px;
  }
}

.SideBarCart_sideBarContainer__15E8f {
  position: fixed;
  display: inline-block;
  height: 100vh;
  width: 0;
  right: 0;
  top: 0;
  margin-bottom: 0;
  background-color: rgb(223, 223, 223);
  transition: 0.5s;
  z-index: 10000;
  overflow: auto;
}

.SideBarCart_emptyContainer__1YV9a {
  position: fixed;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: height 0ms 500ms, opacity 500ms 0ms;
}
.SideBarCart_emptyContainerVisible__2d301 {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: inline-block;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: height 0ms 0ms, opacity 500ms 0ms;
}

.SideBarCart_headerContainer__24lwi {
  padding: 10px 0px;
  width: 100%;
  position: relative;
}

.SideBarCart_closeCart__3Hwza {
  position: absolute;
  right: 10px;
  top: 10px;
}

.SideBarCart_header__1oj60 {
  width: 80%;
  margin-left: 10%;
  font-size: 25px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.SideBarCart_subHeading__230fL {
  width: 80%;
  margin-left: 10%;
}

.SideBarCart_summaryContainer__3B-A2 {
  width: 80%;
  margin-left: 10%;
}

.SideBarCart_summaryColumn__bGRoJ {
  width: 50%;
  display: inline-block;
}

.SideBarCart_summaryColumn__bGRoJ:last-child {
  text-align: right;
}

.SideBarCart_totalText__3voiT {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.SideBarCart_itemsText__3XWts {
  font-size: 15px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.SideBarCart_priceText__r48Kf {
  font-size: 25px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.SideBarCart_checkoutButton__1ewNB {
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.SideBarCart_imagesContainer__2EefT {
  width: 100%;
  padding-bottom: 20px;
}

.SideBarCart_imageContainer__teZiA {
  position: relative;
  width: 80%;
  margin-top: 10px;
  margin-left: 10%;
}

img {
  width: 100%;
  border-radius: 7px;
}

.SideBarCart_removeIcon__2VLdv {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideBarCart_sideImageLoader__3rYWS {
  height: 200px;
  display: flex;
  align-items: center;
}

.History_collectionContainer__2jMi1 {
  padding: 10px;
}

.History_headingContainer__3XQNW {
  width: 80%;
  margin: auto;
  text-align: center;
}

.History_errorContainer__9LNuQ {
  width: 80%;
  margin: auto;
  margin-top: 70px;
  text-align: center;
}

.History_retryIcon__1IkhV svg {
  width: 100px;
  height: 100px;
}

.History_imagesContainer__tBfKW {
  width: 100%;
}

.History_transactionBlock__1F_nF {
  padding: 20px;
  box-shadow: 1px 1px 1px 1px #888888;
  width: 80%;
  border-radius: 10px;
  margin: 20px auto;
}

details > summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.History_rightColumn__3i34p {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95px;
}

details[open] summary {
  padding-bottom: 20px;
}

.History_progressCircle__2Jp-j {
  /* width: 50px; */
  margin: auto;
  text-align: center;
}

.History_arrowIcon__12Arn {
  transform: rotate(0deg);
}

details[open] summary .History_arrowIcon__12Arn {
  transform: rotate(-90deg);
}

.History_orderBlock__20K71 {
  padding: 20px 0 10px;
  border-top: 1.5px solid rgba(72, 72, 72, 0.3);
  display: flex;
  flex-wrap: wrap;
}

.History_clickToPreviewText__1bD3Y {
  opacity: 0.6;
  font-size: 14px;
}

.History_imageCard__aohOR {
  width: 33%;
  padding-bottom: 20px;
}

.History_downloadAllContainer__23rvn {
  text-align: center;
}

.History_continueOrderBlock__2zEMK {
  display: flex;
  justify-content: center;
}
.History_continueOrderContainer__1VNjP {
  max-width: 350px;
  text-align: center;
}

.History_hintText__G4BgG {
  text-align: center;
  margin-top: 30px;
}

.History_emptyIcon__1-ZOv {
  font-size: 150px !important;
}

.History_buttonContainer__13LeV {
  width: 200px;
  margin: 0 auto;
}

.History_downloadProgressButtonContainer__1Y4bd {
  max-width: 250px;
  margin: 25px auto;
}

@media only screen and (min-width: 600px) {
  .History_rightColumn__3i34p svg {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .History_headingContainer__3XQNW {
    width: 95%;
    text-align: left;
  }

  h2 {
    margin-left: 0;
  }

  .History_errorContainer__9LNuQ {
    height: 65vh;
    text-align: center;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .History_emptyIcon__1-ZOv {
    font-size: 100px !important;
    align-self: center;
  }

  .History_transactionBlock__1F_nF {
    width: 95%;
  }

  .History_imageCard__aohOR {
    width: 49%;
  }

  .History_progressCircle__2Jp-j {
    width: 24px;
  }
}

.DownloadModal_modalBackground__2333H {
  background-color: rgba(128, 128, 128, 0.582);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.DownloadModal_modal__1iqJT {
  z-index: 2;
  background-color: white;
  width: 60%;
  max-width: 400px;
  position: relative;
  padding: 40px 40px;
  text-align: center;
}

.DownloadModal_closeModal__3WDc8 {
  position: absolute;
  top: 5px;
  right: 5px;
}

.DownloadModal_headingFont__3yT0_ {
  color: black;
  font-size: 33px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
  text-align: center;
}

.DownloadModal_headerContainer__2BEQb {
  width: 100%;
  text-align: center;
}

.DownloadModal_progressCircle__gPwXk {
  width: 120px;
  height: 120px;
  margin: 20px auto;
}

@media only screen and (max-width: 600px) {
  .DownloadModal_modal__1iqJT {
    width: 90%;
    text-align: center;
  }
}

.Profile_profileContainer__24Cda {
  padding: 10px;
  margin: auto;
}

.Profile_headingContainer__2dVbg {
  width: 80%;
  text-align: center;
}

.Profile_contentContainer__5ofxv {
  width: 80%;
  margin: auto;
}

.Profile_detailsContainer__1bkTn {
  width: 100%;
  padding-bottom: 20px;
}

.Profile_pastSearchWrapper__LcJZL {
  margin-top: 40px;
}

@media only screen and (min-width: 600px) {
  .Profile_contentContainer__5ofxv {
    margin-top: 30px;
  }

  .Profile_detailsContainer__1bkTn {
    margin: auto;
    width: 60%;
  }

  .Profile_detailsRow__N6OXJ {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .Profile_detailsRow__N6OXJ p {
    margin-bottom: 0;
  }
  .Profile_detailsRow__N6OXJ p:first-child {
    width: 30%;
  }

  .Profile_detailsRow__N6OXJ p:last-child {
    text-align: right;
    width: 70%;
  }

  .Profile_schoolName__1z0-x {
    text-align: right;
  }
}

@media only screen and (max-width: 600px) {
  .Profile_headingContainer__2dVbg {
    width: 95%;
    text-align: left;
  }

  .Profile_contentContainer__5ofxv {
    width: 100%;
    margin-top: 30px;
    display: block;
  }

  .Profile_detailsContainer__1bkTn {
    width: 100%;
    text-align: center;
  }

  .Profile_detailsRow__N6OXJ p:first-child {
    margin-bottom: 0;
  }

  h2 {
    margin-left: 0;
  }
}

.Admin_AdminContainer__Ok98b {
  padding: 10px;
  margin: auto;
}

.Admin_headingContainer__x6QHz {
  width: 80%;
  text-align: center;
}

.Admin_contentContainer__2pQtQ {
  width: 80%;
  margin: auto;
}

.Admin_contentContainer__2pQtQ table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.Admin_searchWrapper__2PMPV {
  margin-top: 30px;
  text-align: center;
}

.Admin_pastSearchesContainer__3mf0t {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.Admin_searchWrapper__2PMPV {
  margin-top: 30px;
  text-align: center;
}

.Admin_imageCard__1juS7 {
  width: 33%;
}

.Admin_searchesErrorContainer__2qX5V {
  margin: auto;
}

.Admin_searchesErrorContainer__2qX5V p {
  margin-bottom: 0;
  text-align: center;
}

.Admin_buttonContainer__38_iB {
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}

.Admin_transactions__3Yp2c table {
  width: 100%;
}

.Admin_addUserToFreePurchaseBlock__1_Hw9 {
  width: 50%;
  margin: auto;
}

.Admin_freePurchaseList__1H8Fa {
  margin: auto;
  text-align: center;
}

.Admin_removableDropdown__14_k7 {
  display: flex;
  justify-content: space-between;
}

.Admin_removableDropdown__14_k7 svg {
  margin-top: 27px;
}

.Admin_addSchoolButton__39-h3 {
  font-size: 13px;
  padding-top: 10px;
  text-align: center;
}

.Admin_userExistsContainer__2TZND {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.Admin_checkbox__3V5-m {
  width: 25px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.Admin_checkbox__3V5-m[data-checked="unchecked"]:hover {
  background-color: grey;
}

.Admin_checkbox__3V5-m[data-checked="checked"] {
  background-color: #484848;
}

.Admin_checkbox__3V5-m svg {
  color: white;
}

.Admin_checkboxText__2CM3c {
  max-width: calc(100% - 40px);
  padding-left: 15px;
  display: inline;
  margin-bottom: 0;
}

.Admin_transactionsHeaderBlock__3rdst {
  width: 80%;
  margin: auto;
  text-align: center;
}

.Admin_transactionsHeaderRow__280QW {
  display: flex;
  justify-content: center;
}

.Admin_transactionsHeaderRow__280QW div {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .Admin_headingContainer__x6QHz {
    width: 95%;
    text-align: left;
  }

  .Admin_contentContainer__2pQtQ {
    width: 100%;
    margin-top: 30px;
    display: block;
  }
  .Admin_pastSearchesContainer__3mf0t {
    margin-top: 15px;
    width: 100%;
    display: block;
  }

  .Admin_searchesErrorContainer__2qX5V {
    margin-top: 100px;
  }

  .Admin_imageCard__1juS7 {
    width: 100%;
    margin-bottom: 20px;
  }

  .Admin_addUserToFreePurchaseBlock__1_Hw9 {
    width: 100%;
    margin: auto;
  }
}

.BottomCartDetails_bottomDetailsContainer__2Ex-H {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #484848;
  z-index: 100;
}

.BottomCartDetails_detailsContainer__6wEfX {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  padding: 20px 20px;
  justify-content: space-around;
  width: 300px;
  margin-left: 100px;
}

.BottomCartDetails_row__13KAC {
  display: flex;
  justify-content: space-between;
}

.BottomCartDetails_row__13KAC p {
  margin-bottom: 0;
}

.BottomCartDetails_clearCartContainer__Jq4PX {
  position: fixed;
  bottom: 5px;
  right: 200px;
  color: white;
  display: flex;
  flex-direction: column;
}

.BottomCartDetails_openCartContainer__9-ARV {
  position: fixed;
  bottom: 5px;
  right: 100px;
  color: white;
  display: flex;
  flex-direction: column;
}

.BottomCartDetails_cartIcon__1M6EW {
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .BottomCartDetails_bottomDetailsContainer__2Ex-H {
    border-radius: 8px 8px 0 0;
  }
  .BottomCartDetails_detailsContainer__6wEfX {
    width: calc(80% - 50px);
    margin-left: 0;
  }

  .BottomCartDetails_clearCartContainer__Jq4PX {
    display: none;
  }

  .BottomCartDetails_openCartContainer__9-ARV {
    right: 10px;
  }
}

.AboutUs_aboutUsContainer__Y7BxJ {
  width: 100%;
  padding-bottom: 30px;
}

.AboutUs_textContainer__2eGJ7 {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}

.AboutUs_textContainer__2eGJ7 h3 {
  display: inline;
}

.AboutUs_textContainer__2eGJ7 p {
  margin-bottom: 0;
}

.AboutUs_logo__3oCMm {
  width: 50%;
  margin-bottom: 40px;
}

.AboutUs_textContainer__2eGJ7 ul {
  width: 350px;
  text-align: left;
  margin: 20px auto 0;
}

.AboutUs_inputFieldWrapper__2XTjh {
  margin: auto;
  width: 300px;
  text-align: left;
}

.AboutUs_inputFieldContainer__MHolr {
  display: flex;
  align-items: center;
}

.AboutUs_inputFieldContainer__MHolr h5 {
  margin-bottom: -20px;
  width: 150px;
}

.AboutUs_pricingDetails__3seL2 {
  display: flex;
  margin: auto;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
}

@media only screen and (max-width: 600px) {
  .AboutUs_aboutUsContainer__Y7BxJ {
    padding: 10px;
    width: 95%;
    margin: auto;
  }

  .AboutUs_textContainer__2eGJ7 {
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }

  .AboutUs_logo__3oCMm {
    width: 80%;
  }
}

