.cardContainer {
  width: 100%;
  position: relative;
}

.imageCard {
  width: 98%;
}

.image {
  width: 100%;
  object-fit: cover;
}

.searchAgain {
  position: absolute;
  bottom: 5px;
  width: 98%;
}
.searchAgain > div {
  width: 150px;
  margin: auto;
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.errorContainer > svg {
  width: 50px;
  height: 50px;
}

.errorContainer > p {
  opacity: 0.5;
  padding-top: 10px;
}
