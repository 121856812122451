.homeContainer {
  width: 100%;
  height: calc(95vh - 46px);
  padding: 0 30px;
  text-align: center;
}

.textContainer {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}

h1 {
  width: 80%;
  margin: 0 auto 30px !important;
}

h4 {
  margin-bottom: 30px !important;
}

.textContainer h3 {
  display: inline;
}

.textContainer p {
  margin-bottom: 0;
}

.buttonContainer {
  width: 300px;
  margin: 0 auto 10px;
}

.logo {
  width: 40%;
  height: auto;
  object-fit: cover;
  margin: auto;
}

.topMargin {
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .homeContainer {
    padding: 10px;
    width: 95%;
    margin: auto;
    height: 95vh;
  }

  .textContainer {
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .logo {
    /* width: 80%; */
    display: none;
  }
}
