.profileContainer {
  padding: 10px;
  margin: auto;
}

.headingContainer {
  width: 80%;
  text-align: center;
}

.contentContainer {
  width: 80%;
  margin: auto;
}

.detailsContainer {
  width: 100%;
  padding-bottom: 20px;
}

.pastSearchWrapper {
  margin-top: 40px;
}

@media only screen and (min-width: 600px) {
  .contentContainer {
    margin-top: 30px;
  }

  .detailsContainer {
    margin: auto;
    width: 60%;
  }

  .detailsRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .detailsRow p {
    margin-bottom: 0;
  }
  .detailsRow p:first-child {
    width: 30%;
  }

  .detailsRow p:last-child {
    text-align: right;
    width: 70%;
  }

  .schoolName {
    text-align: right;
  }
}

@media only screen and (max-width: 600px) {
  .headingContainer {
    width: 95%;
    text-align: left;
  }

  .contentContainer {
    width: 100%;
    margin-top: 30px;
    display: block;
  }

  .detailsContainer {
    width: 100%;
    text-align: center;
  }

  .detailsRow p:first-child {
    margin-bottom: 0;
  }

  h2 {
    margin-left: 0;
  }
}
